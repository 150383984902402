const ProviderSubprovider = require('./json-rpc-engine-middleware')
const createSubscriptionManager = require('eth-json-rpc-filters/subscriptionManager')

class SubscriptionsSubprovider extends ProviderSubprovider {
  constructor() {
    super(({ blockTracker, provider, engine }) => {
      const { events, middleware } = createSubscriptionManager({ blockTracker, provider })
      // forward subscription events on the engine
      events.on('notification', (data) => engine.emit('data', null, data))
      // return the subscription install/remove middleware
      return middleware
    })
  }
}

module.exports = SubscriptionsSubprovider
